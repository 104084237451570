
import PaymentType from '../Payment/PaymentType.vue'
export default {
  middleware: ['auth'],
  components: {
    PaymentType
  },
  props: {
    order: {
      type: [Object]
    }
  },
  mounted () {
  },
  methods: {
    paymentStatus (order) {
      let ret = ''

      if (order.order_status === 6) {
        ret = 'คำสั่งซื้อถูกยกเลิก'
      } else if (order.payment_status === 1) {
        ret = ' รอการชำระเงิน'
      } else if (order.payment_status === 2) {
        ret = ' กำลังตรวจสอบหลักฐานการชำระเงิน '
      } else if (order.payment_status === 3) {
        ret = ' ชำระเงินแล้ว'
      }

      let paymentType = ''
      if (parseInt(order.trans_2c2p_creditcard_id) > 0) {
        paymentType = 'ผ่านช่องทาง Credit Card'
      } else if (parseInt(order.trans_2c2p_online_id) > 0) {
        paymentType = 'ผ่านช่องทาง Online banking'
      } else {
        paymentType = ''
      }

      return ret + paymentType
    },
    toText (status) {
      let ret = ''
      // if (status === 2) {
      //   ret = 'รอตรวจสอบกราฟิก'
      // } else
      if (status === 4) {
        ret = 'อยู่ระหว่างจัดส่ง'
      } else if (status === 7) {
        ret = 'อยู่ระหว่างการผลิต'
      } else if (status === 5) {
        ret = 'จัดส่งเรียบร้อย'
      }
      return ret
    },
    fileBaseName (url) {
      const path = require('path')
      return path.basename(url)
    }
  }
}
