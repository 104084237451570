
export default {
  data () {
    return {
    }
  },
  computed: {
    consentLink () {
      const currentLang = this.$i18n.locale
      console.log('currentLang', currentLang)
      return process.env.SCGP_CONSENT_LINK
    }
  }
}
